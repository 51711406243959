import React, { Fragment, useEffect, useState, lazy, Suspense } from "react";
import API from "./API/ClientAPI";
import eventSystem from "./API/eventSystem";
import "./index.css";

// Import Scss
import './assets/scss/theme.scss';
import MessageError from "./components/Forms/MessageError";
import ModalSuccess from "./components/Forms/ModalSuccess";

// Lazy loaded components
const Routes = lazy(() => import("./Routes"));

function App() {
  const [loaded, setLoaded] = useState(API.baseData.loaded);
  const [modalError, setmodalError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  useEffect(() => {
    window.ModalError = e => {
      console.warn(e);
      setmodalError(e);
    }
    window.ModalSuccess = e => setModalSuccess(e);
  }, []);

  if (modalError) eventSystem.RecycledTimeout('ModalError', () => setmodalError(false), 2000);
  if (modalSuccess) eventSystem.RecycledTimeout('ModalSuccess', () => setModalSuccess(false), 2000);

  if (!loaded) {
    eventSystem.waitFor('loadAll', () => API.baseData.loaded, () => setLoaded(true));
  }

  return (
    <React.Fragment>
      {loaded && (
        <Suspense fallback={<div>Loading...</div>}>
          <Routes />
        </Suspense>
      )}
      {modalError && (
        <MessageError modalError={modalError} />
      )}
      {modalSuccess && (
        <ModalSuccess modalSuccess={modalSuccess} />
      )}
    </React.Fragment>
  );
}

export default App;