import React from "react";
import { Modal } from "reactstrap";

export default function ModalSuccess({
  modalSuccess,
}) {
  return (
    <Modal isOpen={true} id="success-btn" centered >
      <div className="modal-body">
        <div className="text-center">
          <i className="bx bx-smile display-1 text-success"></i>
         <h3 className="mt-3">{modalSuccess}</h3>
        </div>
      </div>
    </Modal>
  );
}