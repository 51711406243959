import React from "react";
import { Modal } from "reactstrap";

export default function MessageError({
  modalError,
}) {
	return(
        <Modal isOpen={true } id="success-btn" centered >
            <div className="modal-body">
                <div className="text-center">
                    <i className="bx bx-sad display-1 text-danger"></i>
                    <h3 className="mt-3">{(typeof modalError === 'object') ? modalError.message ?? JSON.stringify(modalError) : modalError}</h3>
                </div>
            </div>
        </Modal>
	);
	}