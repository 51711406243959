const DO_NOT_FORMAT_PHONE_NUMBERS = true;

class StringsTools {
  insert(s, subStr, position) {
    if (position < 0) position = s.length + position;
    var r = s.substr(0, position) + '' + subStr + s.substr(position);
    return r;
  }

  ccodes = [1876, 1869, 1868, 1809, 1784, 1767, 1758, 1473, 1268, 1246, 1242, 998, 996, 995, 994, 993, 992, 986, 977, 976, 975, 974, 973, 972, 971, 968, 967, 966, 965, 964, 963, 962, 961, 960, 880, 856, 855, 850, 692, 691, 688, 686, 685, 680, 679, 678, 677, 676, 675, 674, 673, 670, 598, 597, 595, 593, 592, 591, 509, 507, 506, 505, 504, 503, 502, 501, 423, 421, 420, 389, 387, 385, 381, 381, 380, 378, 377, 376, 375, 374, 373, 372, 371, 370, 359, 358, 357, 356, 355, 354, 353, 352, 351, 291, 269, 268, 267, 266, 265, 264, 263, 261, 260, 259, 257, 256, 255, 254, 253, 252, 251, 250, 249, 248, 245, 244, 243, 242, 241, 240, 239, 238, 237, 236, 235, 234, 233, 232, 231, 230, 229, 228, 227, 226, 225, 224, 223, 222, 221, 220, 218, 216, 213, 212, 98, 95, 94, 93, 92, 91, 90, 86, 84, 82, 81, 77, 66, 65, 64, 63, 62, 61, 60, 58, 57, 56, 55, 54, 53, 52, 51, 49, 48, 47, 46, 45, 44, 43, 41, 40, 39, 39, 38, 36, 34, 33, 32, 31, 30, 27, 20, 7, 1, 1]

  FormatTel(t) {
    if (DO_NOT_FORMAT_PHONE_NUMBERS) return t;
    if (!t) return t;
    t = (t+'').replace(/[^0-9+]/g, '');
    var x = t.replace(/[^0-9]/g, '');
    var areaPos = 0;

    if (t[0] === '+') {
      for (var c in this.ccodes) {
        var C = this.ccodes[c].toString();
        if (C === x.substr(0, C.length)) {
          areaPos = C.length;
          break;
        }
      }
    }
    if (x.length > areaPos) {
      x = this.insert(x, ' ', areaPos);
    }
    /*
    if (x.length > 6) x = this.insert(x, '-', -4);

    if (x.length > 8) {
      var areaPos = 0;
      if (t[0] === '+') {
        for (var c in this.ccodes) {
          var C = this.ccodes[c].toString();
          if (C === x.substr(0, C.length)) {
            areaPos = C.length;
            break;
          }
        }
      }
      if (x.length > areaPos + 8) {
        x = this.insert(x, ')', -8);
        x = this.insert(x, '(', areaPos);
      } else {
        x = this.insert(x, ' ', areaPos);
      }
    }*/ // Oksana hat gebeten, nur Zahlen zu lassen
    return (t[0] === '+' ? '+' : '') + x;
  }
  DMY2YMD(t) {
    if (!t) return '';
    var m = t.match(/([0-9]+).([0-9]+).([0-9]+)/);
    return `${m[3]}-${m[2]}-${m[1]}`;
  }

  YMD2DMY(t) {
    if (!t) return '';
    var m = t.match(/([0-9]+).([0-9]+).([0-9]+)/);
    return `${m[3]}.${m[2]}.${m[1]}`;
  }

  DMY2YMDHM(t) {
    if (!t) return '';
    var m = t.match(/([0-9]+).([0-9]+).([0-9]+).([0-9]+).([0-9]+)/);
    return `${m[3]}-${m[2]}-${m[1]}T${m[4]}:${m[5]}`;
  }

  YMD2DMYHM(t) {
    if (!t) return '';
    var m = t.match(/([0-9]+).([0-9]+).([0-9]+).([0-9]+).([0-9]+)/);
    return `${m[3]}.${m[2]}.${m[1]} ${m[4]}:${m[5]}`;
  }

  DMY2DMY(t) {
    if (!t) return '';
    var m = t.match(/([0-9]+).([0-9]+).([0-9]+)/);
    return m[0];
  }
}

var ST = new StringsTools();
export default ST;