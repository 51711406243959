const DEFAULT_INPUT_TIMEOUT = 3000;

class EventSystem {
  events = {};
  recycledTimeouts = {};

  waitFor(waitTag, condition, action, waiting) {
    if (typeof waitTag === 'function') {
      action = condition;
      condition = waitTag;
      waitTag = 'unnamed wait';
    }
    if (condition())
      action();
    else {
      if (typeof waiting === 'undefined') waiting = 1; else waiting++;
      //if(waiting % 1000 ===0)console.log(`${waitTag} ${Math.round(waiting/1000)}sec`);
      setTimeout(() => eventSystem.waitFor(waitTag, condition, action, waiting), 1);
    }
  }
  RecycledTimeout(tag, action, interval) {
    if (tag in eventSystem.recycledTimeouts)
      clearTimeout(eventSystem.recycledTimeouts[tag]);
    eventSystem.recycledTimeouts[tag] = setTimeout(() => {
      action();
      delete eventSystem.recycledTimeouts[tag];
    }, interval);
  }
  RemoveTimeout(tag) {
    if (tag in eventSystem.recycledTimeouts) {
      clearTimeout(eventSystem.recycledTimeouts[tag]);
      delete eventSystem.recycledTimeouts[tag];
    }
  }
  unsubscribe(name, action) {
    if (name in eventSystem.events) {
      eventSystem.events = eventSystem.events[name].filter(e => e !== action);
    }
  }
  unsubscribeAll(name) {
    eventSystem.events[name] = [];
  }
  subscribe(name, action) {
    if (!(name in eventSystem.events)) {
      eventSystem.events[name] = [action];
    } else {
      eventSystem.events[name].push(action);
    }
  }
  publish(name, params) {
    if (name in eventSystem.events) {
      eventSystem.events[name].forEach(a => a(params));
    }
  }
};
var eventSystem = new EventSystem();

export default eventSystem;