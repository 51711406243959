import eventSystem from './eventSystem.js';
import ST from './StringsTools.js';
import queryString from 'query-string';

export const PRIV_ADMIN = 1;
export const PRIV_MANAGER = 2;
export const PRIV_AGENT = 4;
export const PRIV_BOOKKEEPER = 8;
class ClassAPI {
  useMultiple = true;
  cookieEnabled = true;
  //engine = (document.location.hostname === 'localhost' ? 'https://bustrans.bringstream.com' : '') + "/Engine/";
  engine = (document.location.hostname === 'localhost' ? 'https://winew.bringstream.com' : '') + "/Engine/";
  endpoint = this.engine + "/api/api.php";
  openKey = this.getCookie('openKey');
  privateKey = this.getCookie('privateKey');
  channel = false;
  repeat = false;
  playerSessionId = null;
  currentVideo;
  upnextPlaylist;
  static loadingTimer = null;
  static loadingCount = 0;
  appSettings = {};
  User = {};
  dashboards = null;
  companyId;
  companyName;
  cachedRequests = [];
  cacheTimer = null;

  baseData = { loaded: false };

  constructor() {
    this.openKey = this.getCookie('openKey');
    this.privateKey = this.getCookie('privateKey');
    this.LoadData();
  }

  GetBasicTables(onComplete) {
    this.call("GetBasicTables", { companyId: this.companyId }).then(r => {
      localStorage.removeItem('schedule_start_date')
      this.baseData = r.data;
      this.companies = r.data.companies;
      if (this.companies && this.companyId) this.companyName = this.companies.find(item => item.id === this.companyId).name;
      this.baseData.loaded = true;
      this.baseData.currenciesAssoc = {};
      this.baseData.currencies.forEach(t => { this.baseData.currenciesAssoc[t.id] = t.name; });
      if (typeof (onComplete) === 'function') onComplete();
    }).catch(() => {
      setTimeout(this.LoadData, 500);
    })
  }

  LoadData() {
    setTimeout(() => {
      if (this.openKey && !this.IsLogged()) {
        this.call("LoginWithStoredSession").catch(r => {
          this.Logout();
        })
      }
      this.GetBasicTables();

    }, 1);
  }

  async call(action, params, silent) {

    var qString = `action=${action}`;
    var jsonDataStr;
    var signature, salt;
    var cachable = true;
    if (typeof params === 'undefined') params = {};

    if (this.companyId) params.companyId = this.companyId;
    switch (action) {
      case 'LoginWithSocial':
      case 'LoginAnonymous':
      case 'RegisterWithPassword':
      case 'LoginWithPassword':
      case 'GetBasicTables':
        salt = '~UniHash-kp-45l;t4ldsDSfk-2fd~';
        cachable = false;
        break;
      default:
        cachable = action !== 'Multiple' && this.useMultiple;
        if (!this.openKey || !this.privateKey) {
          //if (typeof onLoginRequired === 'function') prm.onLoginRequired();
          document.location.replace('/logout');
          throw new DOMException("Login required");
        }
        qString += `&openKey=${this.openKey}`;
        salt = this.privateKey;
        break;
    }

    if (!silent)
      this.StartLoading();

    var postDone = r => {
      try {
        if (!silent)
          this.FinishLoading();
        if (typeof r === 'object' && r)
          if (r.status === "error") {
            throw r.message;
          }
        if (action.match(/^(register|login)/i)) {
          if ('aOpenKey' in r && 'aPrivateKey' in r && 'user' in r) {
            this.openKey = r.aOpenKey;
            this.privateKey = r.aPrivateKey;
            this.User = r.user;
            this.companyId = r.user.company_id;
            if (this.companies && this.companyId) this.companyName = this.companies.find(item => item.id === this.companyId).name
            this.setCookie('openKey', r.aOpenKey, 365);
            this.setCookie('privateKey', r.aPrivateKey, 365);
          } else {
            if (!('not_logged_yet' in r)) {
              throw new DOMException('Not enough data from server');
            }
          }
          if ('User' in r) {
            this.User = r.User;
          }
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    };
    var postFail = r => {
      if (!silent)
        this.FinishLoading();
      if (typeof r === 'object') {
        if (r.responseJSON) {
          if (r.responseJSON.message)
            r = r.responseJSON.message;
        } else if ('responseText' in r)
          r = r.responseText;
        else if ('statusText' in r)
          r = r.statusText;
      }
      throw r;
    };

    var response;
    if (cachable)
      return await this.Cache(new CachedRequest(action, params, postDone, postFail));
    else {
      try {
        jsonDataStr = JSON.stringify(params);
        signature = this.sha1(qString + salt + jsonDataStr);
        eventSystem.publish('APICalled', { action: action, params: params });
        eventSystem.publish('APICalled ' + action, params);
        response = await fetch(`${this.endpoint}?${qString}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', },
            body: queryString.stringify({ jsonData: jsonDataStr, signature: signature })
          }).catch(e => {
            //alert(e);
            throw e
          });
        var j = await response.json();
        if (action === 'Multiple') {
          var e = j.find(e => e.status === 'error');
          if (e) throw e.message;
        }
        postDone(j);
        return j;
      } catch (e) {
        postFail(e);
      }
    }

  }

  async Cache(request) {
    var timeout = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    this.cachedRequests.push(request);
    await timeout(2);
    //console.log(`cached ${this.cachedRequests.length}`);
    var qString, signature, jsonDataStr, response, j;
    switch (this.cachedRequests.length) {
      case 0: break;
      case 1:
        var r = this.cachedRequests[0];
        this.cachedRequests = [];
        try {
          qString = `action=${r.action}&openKey=${this.openKey}`;

          jsonDataStr = JSON.stringify(r.params);
          signature = this.sha1(qString + this.privateKey + jsonDataStr);
          eventSystem.publish('APICalled', { action: r.action, params: r.params });
          eventSystem.publish('APICalled ' + r.action, r.params);
          response = await fetch(`${this.endpoint}?${qString}`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded', },
              body: queryString.stringify({ jsonData: jsonDataStr, signature: signature })
            }).catch(e => {
              //alert(e);
              throw e
            });
          j = await response.json();
          r.jResult = j;

        } catch (e) {
          r.error = e;
        }
        break;
      default:
        try {
          qString = `action=Multiple&openKey=${this.openKey}`;
          var requests = this.cachedRequests;
          this.cachedRequests = [];
          var params = [];
          requests.forEach(r => {
            params.push({ action: r.action, params: r.params });
            eventSystem.publish('APICalled', { action: r.action, params: r.params });
            eventSystem.publish('APICalled ' + r.action, r.params);
          })
          jsonDataStr = JSON.stringify({ actions: params, doCommit: true });
          signature = this.sha1(qString + this.privateKey + jsonDataStr);
          response = await fetch(`${this.endpoint}?${qString}`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded', },
              body: queryString.stringify({ jsonData: jsonDataStr, signature: signature })
            }).catch(e => {
              //alert(e);
              throw e
            });
          var cj = await response.json();
          requests.forEach((r, i) => {
            if (cj[i].status === 'error') r.error = cj[i].message;
            else {
              r.jResult = cj[i];
            }
          });
        } catch (e) {
          requests.forEach((r) => {
            r.error = e;
          });
        }
        break;
    }
    for (var i = 0; i < 30000; i += 10) {
      if (request.jResult) {
        //if (typeof request.jResult === 'object') request.jResult = { ...request.jResult };
        request.postDone(request.jResult);
        return request.jResult;
      }
      if (request.error) {
        request.postFail(request.error);
      }
      await timeout(10);
    }
    request.postFail("Connection timeout");
  }

  StartLoading() {
    if (ClassAPI.loadingCount === 0) {
      ClassAPI.loadingTimer = setTimeout(() => {
        //$('#loading').fadeIn();
        ClassAPI.loadingTimer = null;
      }, 500);
    }
    ClassAPI.loadingCount++;
  }

  FinishLoading() {
    ClassAPI.loadingCount--;
    if (ClassAPI.loadingCount === 0) {
      if (ClassAPI.loadingTimer) {
        clearTimeout(ClassAPI.loadingTimer);
        ClassAPI.loadingTimer = null;
      }
      //$('#loading').stop().fadeOut();
    }
  }

  Relogin(onSuccess) {
    return this.call({
      action: 'LoginWithStoredSession', params: {},
      onSuccess: onSuccess,
      onError: r => {
        this.Logout(onSuccess);
      }
    });
  }
  async Logout() {
    this.openKey = false;
    this.privateKey = false;
    this.User = {};
    this.setCookie('openKey', '', false);
    this.setCookie('privateKey', '', false);
  }

  getCompany() {
    return this.companies?.find(e => e.id == this.companyId);
  }

  getCompanyName() {
    if (this.companyName) return this.companyName;
    if (this.companyId > 0) return this.companyName = this.companies?.find(e => e.id == this.companyId)?.name;
    return '';
  }

  IsLogged() {
    return this.User.id > 0 ? 1 : 0;
  }
  HaveIPriv(priv) {
    return ((this.User.privilege & priv) != 0 && this.User.privilege >= priv);
  }
  AmIAdmin() {
    return (this.HaveIPriv(PRIV_ADMIN));
  }

  AmIAgent() {
    return (this.HaveIPriv(PRIV_AGENT));
  }

  AmIBookkeeper() {
    return (this.HaveIPriv(PRIV_BOOKKEEPER));
  }

  AmIAdminOrManager() {
    return (this.HaveIPriv(PRIV_ADMIN) || this.HaveIPriv(PRIV_MANAGER));
  }

  AmIAdminOrManagerOrAgent() {
    return ((this.HaveIPriv(PRIV_ADMIN) || this.HaveIPriv(PRIV_MANAGER) || (this.HaveIPriv(PRIV_AGENT) && !this.AmISite())));
  }

  AmISite() {
    return this.User.site == 1;
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue, exdays) {
    if (this.cookieEnabled || !cvalue) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires + ';path=/';
    }
  }
  sha1(msg) {
    function rotate_left(n, s) {
      var t4 = (n << s) | (n >>> (32 - s));
      return t4;
    }
    ;
    function lsb_hex(val) {
      var str = '';
      var i;
      var vh;
      var vl;
      for (i = 0; i <= 6; i += 2) {
        vh = (val >>> (i * 4 + 4)) & 0x0f;
        vl = (val >>> (i * 4)) & 0x0f;
        str += vh.toString(16) + vl.toString(16);
      }
      return str;
    }
    ;
    function cvt_hex(val) {
      var str = '';
      var i;
      var v;
      for (i = 7; i >= 0; i--) {
        v = (val >>> (i * 4)) & 0x0f;
        str += v.toString(16);
      }
      return str;
    }
    ;
    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, '\n');
      var utftext = '';
      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }
      return utftext;
    }
    ;
    var blockstart;
    var i, j;
    var W = new Array(80);
    var H0 = 0x67452301;
    var H1 = 0xEFCDAB89;
    var H2 = 0x98BADCFE;
    var H3 = 0x10325476;
    var H4 = 0xC3D2E1F0;
    var A, B, C, D, E;
    var temp;
    msg = Utf8Encode(msg);
    var msg_len = msg.length;
    var word_array = new Array();
    for (i = 0; i < msg_len - 3; i += 4) {
      j = msg.charCodeAt(i) << 24 | msg.charCodeAt(i + 1) << 16 |
        msg.charCodeAt(i + 2) << 8 | msg.charCodeAt(i + 3);
      word_array.push(j);
    }
    switch (msg_len % 4) {
      case 0:
        i = 0x080000000;
        break;
      case 1:
        i = msg.charCodeAt(msg_len - 1) << 24 | 0x0800000;
        break;
      case 2:
        i = msg.charCodeAt(msg_len - 2) << 24 | msg.charCodeAt(msg_len - 1) << 16 | 0x08000;
        break;
      case 3:
        i = msg.charCodeAt(msg_len - 3) << 24 | msg.charCodeAt(msg_len - 2) << 16 | msg.charCodeAt(msg_len - 1) << 8 | 0x80;
        break;
    }
    word_array.push(i);
    while ((word_array.length % 16) != 14)
      word_array.push(0);
    word_array.push(msg_len >>> 29);
    word_array.push((msg_len << 3) & 0x0ffffffff);
    for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
      for (i = 0; i < 16; i++)
        W[i] = word_array[blockstart + i];
      for (i = 16; i <= 79; i++)
        W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
      A = H0;
      B = H1;
      C = H2;
      D = H3;
      E = H4;
      for (i = 0; i <= 19; i++) {
        temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      for (i = 20; i <= 39; i++) {
        temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      for (i = 40; i <= 59; i++) {
        temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      for (i = 60; i <= 79; i++) {
        temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      H0 = (H0 + A) & 0x0ffffffff;
      H1 = (H1 + B) & 0x0ffffffff;
      H2 = (H2 + C) & 0x0ffffffff;
      H3 = (H3 + D) & 0x0ffffffff;
      H4 = (H4 + E) & 0x0ffffffff;
    }
    var temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
    return temp.toLowerCase();
  }
};

class CachedRequest {
  action;
  params;
  postDone;
  postFail;
  jResult;
  error;
  Always = [];

  constructor(action, params, postDone, postFail) {
    this.action = action;
    this.params = params;
    this.postDone = postDone;
    this.postFail = postFail;
  }

  always(f) {
    this.Always.push(f);
  }

}

var API = new ClassAPI();
export default API;
